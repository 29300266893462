import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Space from "antd/es/space"
import { FilterIcon } from "lucide-react"
import { useMemo, useState } from "react"

import type {
  DocumentFilterState,
  UseFiltersResult,
} from "../hooks/useDocumentFilters"
import type { AnyUserDocument } from "../knowledge/types"
import type {
  CompletedQuestionnaireKnowledgeItemSource,
  KnowledgeItem,
} from "../knowledge/types"
import { SOURCE_KIND_SHORT_DESCRIPTION } from "../sources/constants"
import { TagListItem } from "../tags/Tag"
import type { GroupTag } from "../tags/types"
import { unique, uniqueByKey } from "../utils"

type UnifiedFilterPopoverProps = {
  tags: GroupTag[]
  knowledgeItems: AnyUserDocument[]
} & UseFiltersResult

const UnifiedFilterPopover: React.FC<UnifiedFilterPopoverProps> = ({
  tags,
  knowledgeItems,
  setFilterValue,
  filterState,
  resetFilters,
  activeFilterCount,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const { creators, sourceKinds, sources, assignees } = useMemo(
    () => ({
      creators: [...new Set(knowledgeItems.map((item) => item.creator.email))],
      sourceKinds: unique(knowledgeItems.map((item) => item.source_kind)),
      sources: uniqueByKey(
        knowledgeItems
          .filter(
            (
              item,
            ): item is KnowledgeItem & {
              source: CompletedQuestionnaireKnowledgeItemSource
            } =>
              item.is_knowledge_item &&
              item.source.kind === "COMPLETED_QUESTIONNAIRE",
          )
          .map((item) => item.source),
        (s) => s.source_oid,
      ),
      assignees: [
        ...new Set(
          knowledgeItems
            .filter((item) => item.last_assigned_to)
            .map((item) => item.last_assigned_to!.email),
        ),
      ],
    }),
    [knowledgeItems],
  )

  const handleFilterChange =
    (key: keyof DocumentFilterState) => (value: string[] | string | null) =>
      setFilterValue(key, value)

  const filterContent = (
    <Space direction="vertical" className="w-60">
      <div className="mt-2 font-semibold">Tags</div>
      <Select
        mode="multiple"
        rootClassName="w-full"
        placeholder="Filter by tags"
        value={filterState.tags}
        onChange={handleFilterChange("tags")}
        optionFilterProp="name"
        options={tags.map((tag) => ({
          value: tag.oid,
          label: <TagListItem tag={tag} />,
          name: tag.name,
        }))}
      />

      <div className="mt-2 font-semibold">Creator</div>
      <Select
        rootClassName="w-full"
        placeholder="Filter by creator"
        value={filterState.creator}
        onChange={handleFilterChange("creator")}
        allowClear
        options={creators.map((creator) => ({
          value: creator,
          label: creator,
        }))}
      />

      {sources.length > 0 && (
        <>
          <div className="mt-2 font-semibold">Source Doc</div>
          <Select
            rootClassName="w-full"
            placeholder="Filter by source doc"
            value={filterState.source}
            onChange={handleFilterChange("source")}
            allowClear
            options={sources.map((source) => ({
              value: source.source_oid,
              label: source.title,
            }))}
          />
        </>
      )}

      <div className="mt-2 font-semibold">Assignee</div>
      <Select
        rootClassName="w-full"
        placeholder="Filter by assigned to"
        value={filterState.assignedTo}
        onChange={handleFilterChange("assignedTo")}
        allowClear
        options={assignees.map((assignee) => ({
          value: assignee,
          label: assignee,
        }))}
      />

      <div className="mt-2 font-semibold">Integration</div>
      <Select
        rootClassName="w-full"
        placeholder="Integration used to sync"
        value={filterState.sourceKind}
        onChange={handleFilterChange("sourceKind")}
        allowClear
        options={sourceKinds.map((kind) => ({
          value: kind,
          label: SOURCE_KIND_SHORT_DESCRIPTION[kind],
        }))}
      />

      <Button className="mt-4" onClick={resetFilters}>
        Clear All Filters
      </Button>
    </Space>
  )

  return (
    <Popover
      content={filterContent}
      title="Filter Options"
      trigger="click"
      open={isVisible}
      onOpenChange={setIsVisible}
    >
      <Button
        icon={<FilterIcon size="1em" />}
        type={activeFilterCount > 0 ? "primary" : "default"}
      >
        {activeFilterCount > 0
          ? `${activeFilterCount} ${activeFilterCount === 1 ? "filter" : "filters"}`
          : "Filter"}
      </Button>
    </Popover>
  )
}

export default UnifiedFilterPopover
