import Card from "antd/es/card"
import Skeleton from "antd/es/skeleton"
import { useEffect, useState } from "react"

interface SlackThread {
  messages: {
    user: string
    text: string
    ts: string
  }[]
}

const SlackThreadPreview = ({ url }: { url: string }) => {
  const [error, setError] = useState<string>()
  const [threadJson, setThreadJson] = useState<SlackThread>()

  useEffect(() => {
    void (async () => {
      try {
        console.log("fetching", url)
        const response = await fetch(url)
        const threadJson = (await response.json()) as SlackThread
        setThreadJson(threadJson)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.toString())
        }
      }
    })()
  }, [url])

  if (error) {
    return (
      <div className="flex w-full items-center justify-center">{error}</div>
    )
  }
  if (!threadJson) {
    return (
      <div className="mb-auto flex w-full flex-col items-center p-4">
        <Skeleton active loading />
        <Skeleton active loading />
      </div>
    )
  }

  // TODO(mgraczyk): Get slack users from IDs.
  const messageCards = threadJson.messages.map((message, i) => {
    return (
      <Card
        key={message.ts}
        title={`@${message.user ?? "unknown"} ${i > 0 ? "replied" : "sent"} at ${new Date(Number(message.ts) * 1000).toLocaleString()}`}
        size="small"
      >
        <div>{message.text}</div>
      </Card>
    )
  })

  return (
    <div className="mb-auto flex flex-col gap-4 overflow-y-auto overflow-x-hidden p-4">
      {messageCards}
    </div>
  )
}

export default SlackThreadPreview
